
// Vue reactivity
import { computed } from 'vue';

// icons
import { add } from 'ionicons/icons';

// components
import { IonPage, IonHeader, IonToolbar, IonTitle, IonContent,
        IonGrid, IonList, IonItem, IonLabel, IonIcon,
        IonThumbnail, IonButtons, IonButton,
        IonRefresher, IonRefresherContent, IonSkeletonText,
        modalController } from '@ionic/vue';

import PostItem from "@/components/PostItem.vue";

// API services
import PostService from '@/services/PostService';
import PostModal from '@/components/PostModal.vue';

import { useI18n } from 'vue-i18n';
import { useStore } from 'vuex';

export default {
  name: 'PostListPage',
  components: { IonHeader, IonToolbar, IonTitle, IonContent, IonPage,
                IonGrid, IonList, IonItem, IonLabel, IonIcon,
                IonThumbnail, IonButtons, IonButton,
                IonRefresher, IonRefresherContent, IonSkeletonText, PostItem, },
  setup() {
    const { t } = useI18n();
    const store = useStore();

    // 1. declare state variables (ref to make them reactive)
    const loading = computed(() => store.state.loadingAppPublicData);
    const loadingUser = computed(() => store.state.loadingUser); // for checking banned posts
    const allPosts = computed(() => store.state.allPosts);
    const userLoggedIn = computed(() => store.state.loggedIn);

    // 2. fetch data from API and store data in state variables
    const fetchAllPosts = (e: any = null) => {
      PostService.getAllPosts().then(res => {
        store.commit('receiveAllPosts', res);
        if (e) e.target.complete(); // dismiss ion-refresher
      });
    };

    // methods or filters
    const openNewPostModal = async () => {
      const modal = await modalController.create({
        component: PostModal,
      });
      modal.onDidDismiss().then(({ data }) => {
        if (data.newPostCreated) {
          fetchAllPosts();
        }
      })
      return modal.present();
    };

    // 3. return variables & methods to be used in template HTML
    return {
      t,

      // icons
      add,

      // variables
      loading, loadingUser, allPosts, userLoggedIn,

      // methods
      openNewPostModal, fetchAllPosts,
    }
  }
}
